<template>
  <div class="home-gallery">
    <div class="home-gallery-container">
      <div class="gallery-container">
        <div class="gallery-grid">
          <div class="gallery-picture s-2x2">
            <img src="../../assets/images/grid-picture-2x2.jpg" alt="Grid picture">
            <div class="gallery-picture-description">
            </div>
          </div>
          <div class="gallery-picture s-2x1">
            <img src="../../assets/images/grid-picture-2x1.jpg" alt="Grid picture">
            <div class="gallery-picture-description">
            </div>
          </div>
          <div class="gallery-picture s-1x2">
            <img src="../../assets/images/grid-picture-1x2.jpg" alt="Grid picture">
            <div class="gallery-picture-description">
            </div>
          </div>
          <div class="gallery-picture s-1x1">
            <img src="../../assets/images/grid-picture-1x1.jpg" alt="Grid picture">
            <div class="gallery-picture-description">
            </div>
          </div>
        </div>
      </div>
      <router-link :to="{name: 'portfolio'}" class="view-more">
        <span>Voir plus</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PictureGrid"
}
</script>

<style scoped lang="less">
div.home-gallery {
  margin: 60px 0;
  padding: 50px 0 20px;
  background-color: #DCDCDC;

  & div.home-gallery-container {
    display: flex;
    flex-direction: column;

    & div.gallery-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      & div.gallery-grid {
        @max-width: 360px;
        @max-height: 300px;
        @3x2: ((100% / 3) * 2);
        @3x1: (100% / 3);
        @gap: 10px;

        display: flex;
        flex-wrap: wrap;
        width: calc(90% + (@gap * 2));
        max-width: @max-width * 3 + @gap * 2;
        margin: @gap auto;

        & div.gallery-picture {
          position: relative;
          margin: @gap / 2;
          overflow: hidden;

          &.s-2x2 {
            width: calc(@3x2 - @gap);
            height: @max-height * 2 - @gap;

            & img {
              min-width: 100%;
              height: 100%;
            }
          }

          &.s-2x1 {
            width: calc(@3x1 - @gap);
            height: @max-height * 2 - @gap;

            & img {
              min-width: 100%;
              height: 100%;
            }
          }

          &.s-1x2 {
            width: calc(@3x2 - @gap);
            height: @max-height - @gap;

            & img {
              width: 100%;
              min-height: 100%;
            }
          }

          &.s-1x1 {
            width: calc(@3x1 - @gap);
            height: @max-height - @gap;

            & img {
              min-width: 100%;
              height: 100%;
            }
          }

          & img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          & div.gallery-picture-description {
            position: absolute;
            display: flex;
            width: 100%;
            bottom: 0;
            transform: translateY(100%);
            background-color: rgba(80, 80, 80, .9);
            transition: transform .2s ease-in-out;

            & span {
              padding: 10px;
              font-size: 12px;
              color: white;
            }
          }

          &:hover div.gallery-picture-description {
            transform: translateY(0);
          }
        }
      }
    }

    & a.view-more {
      margin: 10px auto;
      padding: 9px 15px;
      border: 3px solid black;
      cursor: pointer;
      text-decoration: none;
      background-color: black;

      & span {
        position: relative;
        color: white;
        transition: padding-right .1s, opacity .2s;

        &:after {
          position: absolute;
          right: -10px;
          content: " ...";
          opacity: 0;
        }
      }

      &:hover {
        span {
          padding-right: 10px;

          &:after {
            right: -2px;
            opacity: 1;
          }
        }
      }
    }
  }
}

@media all and (max-width: 800px) {
  div.home-gallery {
    & div.home-gallery-container {
      & div.gallery-container {
        & div.gallery-grid {
          & div {
            width: 100% !important;
          }
        }
      }
    }
  }
}

</style>
